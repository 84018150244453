// Global
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LiveChatWidget } from '@livechat/widget-react';
import Cookies from 'js-cookie';
import CookieConsent from 'react-cookie-consent';

// Redux
import { selectProfile } from '@features/profile/profile';

// Utils
import { convertINameToString } from '@utils/convertINameToString';

export const LiveChat = () => {
  const [liveChatCookieExists, setLiveChatCookieExists] = useState(false);
  const [showLiveChat, setShowLiveChat] = useState(true);
  const { value: profile } = useSelector(selectProfile);

  useEffect(() => {
    const hideDevChat = Cookies.get('devHideChat');
    const enableChat = Cookies.get('enableLiveChat');
    const liveChatCookieExists = !!enableChat;

    if (enableChat === 'false' || !!hideDevChat) {
      setShowLiveChat(false);
    }

    if (liveChatCookieExists) {
      setLiveChatCookieExists(true);
    }
  }, []);

  const customerInfo = !!profile
    ? {
        customerName: `${convertINameToString(profile.customer.name)}`,
        customerEmail: profile.customer.email,
      }
    : {
        customerName: 'Anonymous',
        customerEmail: '-',
      };

  return (
    <>
      {!liveChatCookieExists && (
        <CookieConsent
          location="bottom"
          buttonText="I accept"
          declineButtonText="I decline"
          enableDeclineButton
          style={{
            background: '#FFF',
            color: '#111',
            maxWidth: '970px',
            marginLeft: '50%',
            transform: 'translateX(-50%)',
            boxShadow: '0 0 30px rgba(0,0,0,0.2)',
            padding: '30px',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            flexFlow: 'column',
            flex: 1,
            gap: '20px',
            lineHeight: '1.8',
            zIndex: '999999999999',
          }}
          buttonStyle={{
            color: '#FFF',
            backgroundColor: '#111',
            fontSize: '16px',
            height: '44px',
            borderRadius: '22px',
            padding: '0 30px',
            margin: '0',
          }}
          declineButtonStyle={{
            backgroundColor: '#FFF',
            color: '#111',
            fontSize: '16px',
            height: '44px',
            borderRadius: '22px',
            padding: '0 30px',
            border: '1px solid #FFF',
            margin: '0',
          }}
          contentStyle={{
            flex: 1,
            margin: '0',
          }}
          customButtonProps={{
            tabIndex: 1,
          }}
          customDeclineButtonProps={{
            tabIndex: 2,
          }}
          containerClasses="font-aktiv"
          buttonWrapperClasses="flex flex-row gap-4"
          onAccept={() => {
            Cookies.set('enableLiveChat', 'true', { expires: 365 });
          }}
          onDecline={() => {
            Cookies.set('enableLiveChat', 'false', { expires: 1 });
            setShowLiveChat(false);
          }}
          expires={1}
        >
          <b>Can we store cookies?</b> This website uses cookies to enhance your experience and
          remember your website choices. For more information, read our{' '}
          <a
            className="hover:underline"
            href="https://my.home.cc/privacy"
            target="_blank"
            tabIndex={2} rel="noreferrer"
          >
            privacy policy
          </a>
          .
        </CookieConsent>
      )}
      {showLiveChat && (
        <LiveChatWidget {...customerInfo} license="14763165" visibility="minimized" />
      )}
    </>
  );
};
