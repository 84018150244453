import './tracing';

// Global
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

// Templates
import { Error } from '@templates/Error';

// Utils
import { environmentFor } from './utils/environment';
import { checkIfSentryShouldInit } from '@utils/checkIfSentryShouldInit';

// Local
import { store } from './store';
import { App } from './App';
import './index.css';

const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
const REACT_APP_NAME = process.env.REACT_APP_NAME;

const ENVIRONMENT = environmentFor(window.location.href);

const initSentry = checkIfSentryShouldInit(ENVIRONMENT);

if (initSentry) {
  Sentry.init({
    dsn: 'https://ac5677a32fc4f5e648f5664738500683@o4506060635963392.ingest.sentry.io/4506060663881728',
    environment: ENVIRONMENT,
    // debug: ENVIRONMENT != 'production',
    release: `${REACT_APP_NAME}@${REACT_APP_VERSION}`,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/.*\.home\.cc\/api/],
    tracesSampleRate: 0.1, // 10%
    replaysSessionSampleRate: 0.5, // 50%
    replaysOnErrorSampleRate: 1.0, // 100%
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Sentry.ErrorBoundary
    fallback={<Error title="Something went wrong!!!" text="Reloading may fix this" />}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Sentry.ErrorBoundary>
);
